import CategoryCard from "../components/CategoryCard";

const categories = [
    {
        id: 1,
        title: 'Adventure',
        slug: 'adventure',
        img: '/img/adventure.webp'
    },
    {
        id: 2,
        title: 'Action',
        slug: 'action',
        img: 'img/action.webp'
    },
    {
        id: 3,
        title: 'Romance',
        slug: 'romance',
        img: 'img/love.webp'
    },
    {
        id: 4,
        title: 'Crime',
        slug: 'crime',
        img: 'img/crime.webp'
    },
    {
        id: 5,
        title: 'Horror',
        slug: 'horror',
        img: 'img/horror.webp'
    },
    {
        id: 6,
        title: 'Animation',
        slug: 'animation',
        img: '/img/animation.webp'
    },
    {
        id: 7,
        title: 'Sports',
        slug: 'sports',
        img: '/img/sports.webp'
    },
    {
        id: 8,
        title: 'Mystery',
        slug: 'mystery',
        img: 'img/mystery.webp'
    },
]

export default function Category() {
    return (
        <>
            <div className="flex justify-center mt-[7rem]">
                <div className="flex-col items-center justify-center">
                    <div className="w-[86%] ml-[7%] md:w-[65%] md:ml-[15%]">
                        <p className="text-center mt-5 text-sm md:text-lg">With our user-friendly interface and extensive movie collection,
                            enjoy a seamless and enjoyable movie downloading experience.</p>
                    </div>
                    <div className="flex flex-col items-center  mt-6 md:mt-8 justify-center">
                        <p className="text-white text-md md:text-xl jaldi-bold mb-2">Top Categories</p>
                        <div className="bg-maingreen h-[3px] w-[40%] md:w-[20%] rounded"></div>
                    </div>
                </div>
            </div>
            <div className="w-[90%] mx-auto  mt-8 mb-14">
                <div className="grid grid-cols-2 md:grid-cols-4 gap-5 md:gap-12">
                    {categories && categories.map((category) => (<CategoryCard key={category.id} category={category} />))}
                </div>
            </div>
        </>
    )
}