import { useLocation } from "react-router-dom";
import DowloadList from "../components/DowloadList"
// import Pagination from "../components/Pagination"
import { useDownloads } from "../context/DownloadContext";
import { useEffect } from "react";

function Downloads() {
    const location = useLocation();
    const movie = location.state;
    const { addMovieToDownload, downloadList } = useDownloads()
    // const [page, setpage] = useState(1);
    // const moviesPerPage = 4;
    // const startIndex = (page - 1) * moviesPerPage;
    // const selectedMovies = downloadList?.slice(startIndex, startIndex + moviesPerPage)
    // const totalPages = Math.ceil(downloadList?.length / moviesPerPage)
    useEffect(() => {
        console.log(movie)
        if (movie) {
            console.log("Movie to Add:", movie);
            addMovieToDownload(movie);
        }
    }, [movie, addMovieToDownload]);
    return (
        <div className="flex flex-col items-center justify-center mt-[7rem]">
            <p className="text-white text-md md:text-xl jaldi-bold mb-2">Downloads</p>
            <div className="bg-maingreen h-[3px] w-[40%] md:w-[10%] rounded"></div>
            <DowloadList downloadList={downloadList}/>
            {/* <Pagination page={page} onNext={setpage} totalPages={totalPages}/> */}
        </div>
    )
}

export default Downloads
