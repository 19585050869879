import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

export const useFetchMovies = () => {
    const [movies, setMovies] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        fetch('https://yts.mx/api/v2/list_movies.json?limit=4&sort_by=download_count')
            .then(response => response.json())
            .then(data => {
                if (data.status === "ok" && data.status === "ok") {
                    setMovies(data.data.movies);
                }
                setLoading(false);
            })
            .catch(error => {
                toast.error(error.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "dark",
                });
                setLoading(false);
            });
    }, []);
    return { movies, loading };
};
