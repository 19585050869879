import { Link } from "react-router-dom";

export default function CategoryCard({ category }) {
    return (
        <Link to={`/categories/${category.slug}`}>
            <div className="relative overflow-hidden items-center flex-col hover:cursor-pointer text-maingreen justify-center h-[5rem] md:h-[10rem] flex rounded-lg">
                <div style={{ backgroundImage: `url(${category.img})` }} className="absolute inset-0 bg-cover bg-center transition-transform duration-500 ease-out transform scale-100 hover:scale-110"></div>
                <span className="relative jaldi-bold text-lg md:text-2xl text-white">
                    {category.title}
                </span>
            </div>
        </Link>
    )
}