import MovieList from "./MovieList";
import NoResult from "./NoResult";

export default function Movies({ moviesList, title }) {
    return (
        <div className="flex flex-col items-center mt-6 md:mt-8">
            <p className="text-white text-md md:text-xl jaldi-bold mb-2">{title.toUpperCase()}</p>
            <div className="bg-maingreen h-[3px] w-[40%] md:w-[14%] rounded"></div>
            {
                moviesList === null ? (<NoResult />) : (
                    <MovieList moviesList={moviesList} />
                )
            }
        </div>
    )
}