import { useEffect, useState } from "react";
import { toast } from 'react-toastify';

export const useFetchSuggetions = (movieId) => {
    const [movies, setMovies] = useState(null);
    const [isLoading, seIsLoading] = useState(true);
    useEffect(() => {
        fetch(`https://yts.mx/api/v2/movie_suggestions.json?movie_id=${movieId}`)
            .then(response => response.json())
            .then(data => {
                if (data.status === "ok" && data.data.movies.length !== 0) {
                    setMovies(data.data.movies);
                } else {
                    setMovies(null)
                }
                seIsLoading(false);
            })
            .catch(error => {
                console.log(error)
                toast.error(error.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "dark",
                });
                seIsLoading(false);
            });
    }, [movieId]);
    return { movies, isLoading };
}