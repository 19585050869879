import { useNavigate, useParams } from 'react-router-dom'
import SuggetionList from '../components/SuggetionList'
import Loader from '../components/InfinityLoader';
import { useFetchSuggetions } from '../context/SuggetionData';
import { useFetchSingleMovie } from '../context/singleMovieData';
import NoResult from '../components/NoResult';
import Error from './404';
import { convertMinutesToHoursAndMinutes } from '../util/ConvertRuntime';

export default function SingleMovie() {
    const { id } = useParams()
    const { movie, loading } = useFetchSingleMovie(id)
    const { movies, isLoading } = useFetchSuggetions(id)
    const navigate = useNavigate();
    return (
        <>
            {
                loading || isLoading ? (
                    <Loader />
                ) : (
                    <>
                        {movie === null ? (<Error />) : (
                            <>
                                <div className="w-[80%] md:flex items-center mx-auto mt-[7rem]">
                                    <div className="w-[80%] md:w-[20%] mx-auto md:mx-0 relative overflow-hidden">
                                        <img className="rounded-lg transition-all duration-300 hover:scale-110 " src={movie?.large_cover_image}
                                            alt={movie?.title} />
                                    </div>
                                    <div className="w-full md:w-[70%] mx-auto space-y-3 text-center md:text-start md:space-y-5 mt-5 md:mt-0">
                                        <span className="jaldi-bold text-lg text-center md:text-2xl">{movie?.title_long}</span>
                                        <div className="flex w-[100%] md:w-[30%] justify-between text-xs md:text-sm my-1">
                                            <span className="flex items-center text-sm md:text-base text-maingreen">
                                                <svg className="me-1 w-[20px] h:[20px]  text-maingreen" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <path stroke="#00D595" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.3" d="M4 10h16m-8-3V4M7 7V4m10 3V4M5 20h14a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1Zm3-7h.01v.01H8V13Zm4 0h.01v.01H12V13Zm4 0h.01v.01H16V13Zm-8 4h.01v.01H8V17Zm4 0h.01v.01H12V17Zm4 0h.01v.01H16V17Z" />
                                                </svg>
                                                {movie?.year}
                                            </span>
                                            <span className="flex items-center text-sm md:text-base text-maingreen">
                                                <svg className="me-1 w-[20px] h:[20px] text-maingreen" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="#00D595" viewBox="0 0 24 24">
                                                    <path d="M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z" />
                                                </svg>
                                                {movie?.rating}
                                            </span>
                                            <span className="flex items-center text-sm md:text-base text-maingreen">
                                                <svg className="me-1 w-[20px] h:[20px]   text-maingreen" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <path stroke="#00D595" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M12 8v4l3 3m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                                </svg>
                                                {convertMinutesToHoursAndMinutes(movie?.runtime)}
                                            </span>
                                            <span className="flex items-center text-sm md:text-base text-maingreen">
                                                <svg className="me-1 w-[20px] h:[20px]   text-maingreen" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <path stroke="#00D595" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m13 19 3.5-9 3.5 9m-6.125-2h5.25M3 7h7m0 0h2m-2 0c0 1.63-.793 3.926-2.239 5.655M7.5 6.818V5m.261 7.655C6.79 13.82 5.521 14.725 4 15m3.761-2.345L5 10m2.761 2.655L10.2 15" />
                                                </svg>
                                                {movie?.language}
                                            </span>
                                        </div>
                                        <span className="text-maingreen  inline-flex">
                                            <svg className="w-6 h-6 me-1 text-maingreen" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#00D595" viewBox="0 0 24 24">
                                                <path d="M11 9a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z" />
                                                <path fillRule="evenodd" d="M9.896 3.051a2.681 2.681 0 0 1 4.208 0c.147.186.38.282.615.255a2.681 2.681 0 0 1 2.976 2.975.681.681 0 0 0 .254.615 2.681 2.681 0 0 1 0 4.208.682.682 0 0 0-.254.615 2.681 2.681 0 0 1-2.976 2.976.681.681 0 0 0-.615.254 2.682 2.682 0 0 1-4.208 0 .681.681 0 0 0-.614-.255 2.681 2.681 0 0 1-2.976-2.975.681.681 0 0 0-.255-.615 2.681 2.681 0 0 1 0-4.208.681.681 0 0 0 .255-.615 2.681 2.681 0 0 1 2.976-2.975.681.681 0 0 0 .614-.255ZM12 6a3 3 0 1 0 0 6 3 3 0 0 0 0-6Z" clipRule="evenodd" />
                                                <path d="M5.395 15.055 4.07 19a1 1 0 0 0 1.264 1.267l1.95-.65 1.144 1.707A1 1 0 0 0 10.2 21.1l1.12-3.18a4.641 4.641 0 0 1-2.515-1.208 4.667 4.667 0 0 1-3.411-1.656Zm7.269 2.867 1.12 3.177a1 1 0 0 0 1.773.224l1.144-1.707 1.95.65A1 1 0 0 0 19.915 19l-1.32-3.93a4.667 4.667 0 0 1-3.4 1.642 4.643 4.643 0 0 1-2.53 1.21Z" />
                                            </svg>
                                            <div className=''>
                                                {movie?.genres.map((item, index) => (<span className=' text-maingreen me-1' key={index}>{item} {index < movie?.genres.length - 1 && "|"}</span>))}
                                            </div>
                                        </span>
                                        <p className="text-sm md:text-base text-justify">
                                            {movie?.description_full}
                                        </p>
                                        <div className="grid grid-cols-2 gap-x-2 md:grid md:grid-cols-4 md:gap-x-5">
                                            {movie?.torrents?.map((item, index) => (
                                                <button onClick={() => {
                                                    navigate('/downloads',{ state: movie })
                                                }} key={index} className="mt-2 mb-2 inline-flex items-center justify-center bg-maingreen hover:cursor-pointer text-xs md:text-sm text-black py-2 px-4 rounded ">
                                                    <svg className="me-2 w-4 h-4 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                        <path stroke="#0B0B0B" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 13V4M7 14H5a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1h-2m-1-5-4 5-4-5m9 8h.01" />
                                                    </svg>
                                                    {item.quality} {item.type} ({item.size})
                                                </button>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col items-center mt-6 md:mt-10">
                                    <p className="text-white text-md md:text-xl jaldi-bold mb-2">Suggested for you</p>
                                    <div className="bg-maingreen h-[3px] w-[40%] md:w-[14%] rounded"></div>
                                    {movie && movies === null ? (<NoResult />) : (<SuggetionList suggestedMovies={movies} />)}
                                </div>
                            </>
                        )}
                    </>
                )
            }
        </>
    )
}