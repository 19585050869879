export default function Error() {
    return (
        <>
            <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6 mt-[8rem]">
                <div class="mx-auto max-w-screen-sm text-center">
                    <h1 class="mb-4 text-7xl text-maingreen tracking-tight font-extrabold lg:text-9xl text-primary-600 dark:text-primary-500">404</h1>
                    <p class="mb-4 text-3xl tracking-tight font-semibold text-white md:text-4xl">Something's missing.</p>
                    <p class="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">Sorry, we can't find that page. You'll find lots to explore on the home page. </p>
                    <a href="/" class="inline-flex text-maingreen bg-primary-600 hover:bg-primary-800 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center my-2">Back to Homepage</a>
                </div>
            </div>
        </>
    )
}