import SuggetionMovieCard from "./SuggetionMovieCard";

export default function SiggestionList({ suggestedMovies }) {
    return (
        <>
            <div className="w-[80%] mx-auto mt-6 mb-14">
                <div className="grid grid-cols-1 md:grid-cols-4 gap-5 md:gap-12">
                    {suggestedMovies && suggestedMovies.map((movie, index) => (<SuggetionMovieCard key={index} sugesstedMovie={movie} />))}
                </div>
            </div>
        </>
    )
}