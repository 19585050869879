export default function About() {
    return (
        <>
            <div className="flex flex-col items-center justify-center mt-[7rem]">
                <p className="text-white text-md md:text-xl jaldi-bold mb-2">About</p>
                <div className="bg-maingreen h-[3px] w-[40%] md:w-[10%] rounded"></div>
                <img className="w-[80%] md:h-full my-5 md:my-10" alt="about img" src={'/img/about.webp'} />
                <div className="w-[80%] mb-2">
                    <p className="text-justify text-sm md:text-base">
                        Welcome to Torrent Flix, your ultimate destination for high-quality movie downloads in the smallest file sizes. Inspired by the popular YTS YIFY platform, our website is dedicated to offering a vast collection of movies in stunning 720p, 1080p, and 4K resolutions, ensuring you enjoy the best viewing experience without consuming excessive storage space.
                        <br /><br />
                        Our mission is to provide movie enthusiasts with a streamlined, user-friendly platform to easily search, discover, and download movies from a variety of genres. Whether you're into thrillers, comedies, dramas, or action films, you'll find a comprehensive library that caters to all tastes.
                        <br /><br />
                        At Torrent Flix, we prioritize both quality and convenience. Our commitment to delivering HD movies in compact file sizes means you can enjoy faster downloads without compromising on visual excellence. Explore our collection, watch trailers, read summaries, and download your favorite movies with just a few clicks.
                    </p>
                </div>
                <div className="py-3">
                    <p className="text-center text-sm">&copy; 2024 <a href="https://semindi.me" className="text-maingreen">Hiranya Semindi</a></p>
                    <p className="text-center text-sm">Powered by <a href="https://yts.mx" className="text-maingreen">yts.mx</a></p>
                </div>
            </div>
        </>
    )
}