import { Link } from "react-router-dom";
import { convertMinutesToHoursAndMinutes } from "../util/ConvertRuntime";

export default function SuggetionMovieCard({ sugesstedMovie }) {
    return (
        <Link to={`/movie/${sugesstedMovie.id}`}>
            <div className="">
                <div className="relative overflow-hidden hover:shadow-[0px_2px_15px_1px_#00D595] transition duration-700 items-center flex-col hover:cursor-pointer text-maingreen justify-center h-[8rem] md:h-[10rem] flex rounded-lg">
                    <div className="h-[200px] ">
                        <img className="object-cover w-full h-full" src={sugesstedMovie.background_image} alt={sugesstedMovie.title} />
                    </div>
                    <div className="absolute h-full w-full bg-black/70 flex space-y-2 md:space-y-4 flex-col p-[1.3rem] md:p-5 items-center justify-center -bottom-0 opacity-70 transition-all duration-300">
                        <p className="text-center text-sm md:text-lg jaldi-bold">{sugesstedMovie.title.length > 15 ? sugesstedMovie.title.slice(0, 15) + '...' : sugesstedMovie.title}</p>
                        <div className="flex space-x-1 md:space-x-4 text-xs md:text-sm my-1">
                            <span className="flex flex-row items-center text-xs md:text-sm text-maingreen">
                                <svg className="me-[0.12rem] md:me-2 w-[10px] h:[10px] md:w-[18px] md:h-[18px] text-maingreen" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                    <path stroke="#00D595" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.3" d="M4 10h16m-8-3V4M7 7V4m10 3V4M5 20h14a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1Zm3-7h.01v.01H8V13Zm4 0h.01v.01H12V13Zm4 0h.01v.01H16V13Zm-8 4h.01v.01H8V17Zm4 0h.01v.01H12V17Zm4 0h.01v.01H16V17Z" />
                                </svg>
                                {sugesstedMovie.year}
                            </span>
                            <span className="flex flex-row items-center text-xs md:text-sm text-maingreen">
                                <svg className="me-[0.12rem] md:me-2 w-[10px] h:[10px] md:w-[18px] md:h-[18px]  text-maingreen" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="#00D595" viewBox="0 0 24 24">
                                    <path d="M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z" />
                                </svg>
                                {sugesstedMovie.rating}
                            </span>
                            <span className="flex flex-row items-center text-xs md:text-sm text-maingreen">
                                <svg className="me-[0.12rem] md:me-2 w-[10px] h:[10px] md:w-[18px] md:h-[18px]  text-maingreen" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                    <path stroke="#00D595" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M12 8v4l3 3m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                </svg>
                                {convertMinutesToHoursAndMinutes(sugesstedMovie.runtime)}
                            </span>
                        </div>
                        <button className="mt-2 mb-2 inline-flex items-center justify-center bg-white hover:cursor-pointer hover:bg-opacity-100 text-xs md:text-sm text-black py-[0.3rem] px-2 rounded w-full">
                            <svg className="me-2 w-4 h-4 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <path stroke="#0B0B0B" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778" />
                            </svg>
                            View More
                        </button>
                    </div>
                </div>
            </div>
        </Link>
    )
}

