export default function Help() {
    return (
        <>
            <div className="flex flex-col items-center justify-center mt-[7rem]">
                <p className="text-white text-md md:text-xl jaldi-bold mb-2">Help</p>
                <div className="bg-maingreen h-[3px] w-[40%] md:w-[10%] rounded"></div>
                <img className=" w-[60%] md:w-[20%] my-5 md:my-10" alt="about img" src={'/img/torrent_logo.webp'} />
                <div className="w-[80%] mb-2 ">
                    <p className="text-justify text-sm md:text-base">
                        <span className="jaldi-bold ">What is torrent file?</span> <br /><br />
                        A torrent file acts like a table of contents (index) that allows computers to find information through the use of a Bittorrent client. With the help of a torrent file, one can download small parts of the original file from computers that have already downloaded it. These "peers" allow for downloading of the file in addition to, or in place of, the primary server.
                        <br /><br />
                        <span className="jaldi-bold">How to download movie using torrent file</span><br /><br />
                        First, you need to install the BitTorrent client. After installing the BitTorrent client, double-click the file you downloaded from this site.<br />
                        <a href="https://www.bittorrent.com/products" className="text-maingreen underline">Download BitTorrent Client</a><br /><br />
                        <span className="jaldi-bold">Is there another way to download?</span><br /><br />
                        You can use seedr.cc to download torrent files without BitTorrent client. First, you need to go and register with seedr.cc. After that, download the torrent file from our site and drag and drop it to seedr. Then you can download your movie faster
                    </p>
                </div>
            </div >
        </>
    )
}