import { useEffect, useState } from "react";
import { toast } from "react-toastify";

export const useFetchMoviesByCategories = (genre) => {
    const [movies, setmovies] = useState([]);
    const [loading, setloading] = useState(true);
    useEffect(() => {
        fetch(`https://yts.mx/api/v2/list_movies.json?genre=${genre}`)
            .then(response => response.json())
            .then(data => {
                if (data.status === "ok" && data.movie_count !== 0) {
                    setmovies(data.data.movies)
                } else {
                    setmovies(null)
                }
                setloading(false)
            })
            .catch(error => {
                toast.error(error.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "dark",
                });
                setloading(false)
            })
    }, [genre]);

    return { movies, loading }
}