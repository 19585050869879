export default function Hero() {
    return (
        <div className="flex justify-center mt-[7rem]">
            <div className="flex-col items-center justify-center">
                <p className="jaldi-bold text-4xl md:text-6xl text-maingreen text-center">Let's Make It Easy</p>
                <div className="w-[86%] ml-[7%] md:w-[65%] md:ml-[15%]">
                    <p className="text-center mt-5 text-sm md:text-lg">With our user-friendly interface and extensive movie collection,
                        enjoy a seamless and enjoyable movie downloading experience.</p>
                </div>
            </div>
        </div>
    )
}