import DownloadCard from "./DownloadCard"

function DowloadList({downloadList}) {
    return (
        <div className="flex flex-col items-center mt-6 md:mt-8">
            <div className="w-[90%] mx-auto md:w-[80%]" >
                <div className="grid grid-cols-2 md:grid-cols-4 mt-8 gap-4 md:gap-10  ">
                    {downloadList && downloadList.map((movie, index) => <DownloadCard key={movie.id} movie={movie} />)}
                </div>
            </div>
        </div>
    )
}

export default DowloadList
