import MainMovieCard from "./MainMovieCard";

export default function MovieList({ moviesList }) {
    return (
        <>
            <div className="w-[95%] mx-auto md:w-[86%]" >
                <div className="grid md:flex grid-cols-2 md:justify-center mt-8 gap-4 md:gap-0 space-x-0 md:space-x-20 w-full mx-auto ">
                    {moviesList && moviesList.map(((movie) => (<MainMovieCard key={movie.id} movie={movie} />)))}
                </div>
            </div>
        </>
    )
}