import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

export const useFetchSingleMovie = (movieId) => {
    const [movie, setmovie] = useState(null);
    const [loading, setloading] = useState(true);
    useEffect(() => {
        fetch(`https://yts.mx/api/v2/movie_details.json?movie_id=${movieId}`)
            .then(response => response.json())
            .then(data => {
                if (data.status === "ok" && data.data.movie.id !== 0) {
                    setmovie(data.data.movie);
                }
                setloading(false)
            })
            .catch(error => {
                toast.error(error.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "dark",
                });
                setloading(false)
            })
    }, [movieId]);
    return { movie, loading }
}
