import { createContext, useContext, useState } from "react"

const DownloadContext = createContext();

function DownloadProvider({ children }) {
    const [downloadList, setdownloadList] = useState([]);
    const addMovieToDownload = (movie) => {

        setdownloadList((prevMovies) => {
            if (!prevMovies.some((m) => m.id === movie.id)) {
                return [...prevMovies, movie];
            }
            return prevMovies;
        });
    }
    return (
        <DownloadContext.Provider value={{ addMovieToDownload, downloadList }}>
            {children}
        </DownloadContext.Provider>
    )
}

export default DownloadProvider

export const useDownloads = () => useContext(DownloadContext)