export default function Pagination({ page, onNext, totalPages }) {
    return (
        <div className='flex items-center justify-center mt-8'>
            <nav aria-label="Page navigation example">
                <ul className="flex items-center -space-x-px h-8 text-sm">
                    <li>
                        <span onClick={() => { onNext((page => Math.max(page - 1, 1))) }} className="flex hover:cursor-pointer items-center justify-center px-3 h-8 ms-0 leading-tight text-maingreen hover:text-gray-700 border border-e-0  border-[#00D595] rounded-s-lg hover:bg-[#124636]">
                            <span className="sr-only">Previous</span>
                            <svg className="w-2.5 h-2.5 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                <path stroke="#00D595" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 1 1 5l4 4" />
                            </svg>
                        </span>
                    </li>
                    {Array.from({ length: totalPages }, (_, index) => (
                        <li key={index} onClick={() => { onNext(index + 1) }}>
                            <span className={`flex hover:cursor-pointer items-center justify-center px-3 h-8 leading-tight text-maingreen ${page === index + 1 ? 'bg-[#09583ff4]' : 'bg-transparent'} border border-[#00D595] hover:bg-maingreen hover:text-gray-700 `}>{index + 1}</span>
                        </li>
                    ))}
                    <li>
                        <span onClick={() => { onNext((page) => Math.min(page + 1, totalPages)) }} className="flex hover:cursor-pointer items-center justify-center px-3 h-8 leading-tight text-maingreen bg-transparent border border-[#00D595] rounded-e-lg hover:bg-[#124636] hover:text-gray-700">
                            <span className="sr-only">Next</span>
                            <svg className="w-2.5 h-2.5 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                <path stroke="#00D595" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4" />
                            </svg>
                        </span>
                    </li>
                </ul>
            </nav>
        </div>
    )
}