import { useState } from "react"
import Movies from "../components/Movies"
import { useFetchMoviesByCategories } from "../context/SearchCategoryData"
import Loader from "../components/InfinityLoader";
import { useParams } from "react-router-dom";
import Pagination from "../components/Pagination";

export default function SearchCategories() {
    const { genre } = useParams();
    const [page, setpage] = useState(1);
    const { movies, loading } = useFetchMoviesByCategories(genre)
    const moviesPerPage = 4;
    const startIndex = (page - 1) * moviesPerPage;
    const selectedMovies = movies?.slice(startIndex, startIndex + moviesPerPage)
    const totalPages = Math.ceil(movies?.length / moviesPerPage)
    return (
        <>
            {loading ? <Loader /> : (
                <>
                    <div className="flex justify-center mt-[7rem]">
                        <div className="flex-col items-center justify-center">
                            <div className="w-[86%] ml-[7%] md:w-[65%] md:ml-[15%]">
                                <p className="text-center mt-5 text-sm md:text-lg">With our user-friendly interface and extensive movie collection,
                                    enjoy a seamless and enjoyable movie downloading experience.</p>
                            </div>
                        </div>
                    </div>
                    <Movies moviesList={movies === null ? null : selectedMovies} title={genre} />
                    {movies === null ? (<></>) : (<Pagination page={page} onNext={setpage} totalPages={totalPages} />)}
                </>
            )}
        </>

    )
}