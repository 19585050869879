import { useState } from 'react';
import Movies from '../components/Movies'
import { useFetchSearchMovies } from '../context/SearchMovieData'
import Loader from '../components/InfinityLoader';
import Pagination from '../components/Pagination';

export default function Search() {
    const [query, setquery] = useState('');
    const [page, setpage] = useState(1);
    const { movies, loading } = useFetchSearchMovies(query)
    const moviesPerPage = 4;
    const startIndex = (page - 1) * moviesPerPage;
    const selectedMovies = movies?.slice(startIndex, startIndex + moviesPerPage)
    const totalPages = Math.ceil(movies?.length / moviesPerPage)
    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <>
                    <div className="w-[80%] md:w-[30%] mx-auto mt-[8rem]">
                        <div className="flex-col items-center justify-center mb-10">
                            <div className="relative ">
                                <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                                    <svg className="w-6 h-6 me-2 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                        <path stroke="currentColor" strokeLinecap="round" strokeWidth="2" d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z" />
                                    </svg>
                                </div>
                                <input onChange={(e) => setquery(e.target.value)} type="text" className="bg-transparent border border-white text-white text-sm rounded-lg focus:outline-none w-full focus:border-white block ps-10 p-2.5" placeholder="Search for a movie" />
                            </div>
                        </div>
                    </div>
                    <Movies moviesList={movies === null ? null : selectedMovies} title={'Top Results'} />
                    {movies === null ? (<></>) : (<Pagination page={page} onNext={setpage} totalPages={totalPages} />)}
                </>
            )}
        </>
    )
}