export default function NoResult() {
    return (
        <>
            <div className="flex flex-col py-20 items-center mt-6 md:mt-8">
                <svg className="w-[98px] h-[98px] text-maingreen" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                    <path stroke="#00D595" strokeLinecap="round" strokeWidth="1.8" d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z" />
                </svg>
                <p className="text-white pt-5 text-md md:text-xl mb-2">No Results Found</p>
            </div>
        </>
    )
}