import Hero from '../components/Hero'
import Movies from '../components/Movies'
import Loader from '../components/InfinityLoader'
import "react-toastify/dist/ReactToastify.css";
import { useFetchMovies } from '../context/homePageData';

export default function Home() {
    const { movies, loading } = useFetchMovies()
    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <>
                    <Hero />
                    <Movies moviesList={movies} title={'Trending Downloads'} />
                </>
            )}
        </>
    )
}