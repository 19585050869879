function DownloadCard({movie}) {
    return (
        <div className="relative group overflow-hidden mt-2 items-center flex-col hover:cursor-pointer text-maingreen justify-center flex rounded-lg">
            <div className="">
                <img className="object-cover group-hover:scale-110 transition duration-500 w-full h-full hover:scale-105" src={movie.large_cover_image} alt={movie.title} />
            </div>
            <div className="absolute h-full w-full bg-black/80 flex space-y-2 md:space-y-4 flex-col p-[1.3rem] md:p-5 items-center justify-center -bottom-0 opacity-70 transition-all duration-300">
                <p className="text-center jaldi-bold text-base md:text-xl">{movie.title}</p>
                <div className="flex space-x-1 md:space-x-4 text-sm md:text-base my-1">
                    <span className="flex flex-row items-center text-base md:text-lg text-maingreen">
                        <svg class="w-6 h-6 me-1 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="#00D595" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 13V4M7 14H5a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1h-2m-1-5-4 5-4-5m9 8h.01" />
                        </svg>
                        Downloading...
                    </span>
                </div>
                <div class="w-full rounded-full bg-gray-600">
                    <div class="bg-maingreen text-xs w-[45%] font-medium text-black text-center p-0.5 leading-none rounded-full" > 45%</div>
                </div>
            </div>
        </div>
    )
}

export default DownloadCard
